<template>
  <footer class="s-footer">
    <div ref="taglineWrapper" class="tagline-message fs--large">
      <div ref="tagline" class="tagline" v-html="footerMessage.join('')" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col col-logo">
          <a class="logo-link mb-m" to="/">
            <logo-svg class="logo-img" />
          </a>
          <p class="rights mb-m">
            © 2024 CLICK2ID.RU, All rights reserved.
          </p>
          <div class="wrapper-social">
            <a href="" class="social-link" target="_blank">
              <icon-vk />
            </a>
          </div>
          <!--
          <router-link class="fs--small mb-xs" to="/information">
            Раскрытие информации
          </router-link>
          -->
          <a
            class="fs--small mb-xs"
            href="assets/Политика конфиденциальности.pdf"
            target="_blank"
            rel="noopener"
          >
            Политика конфиденциальности
          </a>
          <a
            class="fs--small mb-xs"
            href="assets/Заявление.pdf"
            target="_blank"
            rel="noopener"
          >
            Заявление о конфиденциальности
          </a>
        </div>
        <div class="col col-menu">
          <!--
          <router-link class="menu-link" to="/activation#hero">Активация</router-link>
          <router-link class="menu-link" to="/renewal#hero">Продление</router-link>
          <router-link class="menu-link" to="/change#hero">Смена держателя</router-link>
          <router-link class="menu-link" to="/agreements">Соглашения</router-link>
          -->
          <router-link class="menu-link" to="/login">Вход для партнеров</router-link>
          <router-link class="menu-link" to="/collaboration">Сотрудничество</router-link>
          <!--
          <router-link class="menu-link" to="/faq#delivery">Доставка</router-link>
          <router-link class="menu-link" to="/auth">Личный кабинет</router-link>
          -->
          <router-link class="menu-link" to="/faq#pay">Оплата</router-link>
          <router-link class="menu-link" to="/articles">Статьи</router-link>
          <router-link class="menu-link" to="/contacts">Контакты</router-link>
          <router-link class="menu-link" to="/requisites">Реквизиты</router-link>
        </div>
        <div class="col col-contacts">
          <app-button class="mb-m" title="8 800 4444308" description="Консультация и поддержка клиентов">
            <icon-call class="icon" />
          </app-button>
          <app-button class="mb-m" title="8 499 6854308" description="Help & customer support">
            <icon-call class="icon" />
          </app-button>
                    <!--
          <div class="wrapper-social">
            <a href="https://vk.com/" class="social-link" target="_blank">
              <icon-vk />
            </a>
          </div>
                    -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import LogoSvg from '@/assets/img/logo.svg'
import IconCall from '@/assets/img/icons/call.svg'
//import IconVk from '@/assets/img/icons/vk.svg'


export default {
  name: 'AppFooter',
  components: {
    AppButton,
    LogoSvg,
    IconCall,
    //IconVk
  },
  data () {
    return {
      footerMessage: []
    }
  },
  beforeMount () {
    this.generateFooterMessage()
  },
  mounted () {
    this.startStringAnimation(50000)
  },
  methods: {
    startStringAnimation (duration) {
      const tagline = this.$refs.tagline
      const innerWidth = tagline.offsetWidth
      const cloneEl = tagline.cloneNode(true)
      this.$refs.taglineWrapper.appendChild(cloneEl)

      let start = performance.now()
      let progress
      let translateX

      requestAnimationFrame(function step(now) {
        progress = (now - start) / duration

        if (progress > 1) {
          progress %= 1
          start = now
        }

        translateX =  innerWidth * progress

        tagline.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        cloneEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        requestAnimationFrame(step)
      })
    },
    generateFooterMessage () {
      const singleMessageLength = 798
      let currentMessageLength = 0
      let counter = 0

      while (currentMessageLength < window.innerWidth) {
        this.footerMessage.push('<span class="dark">&nbsp;CLICK</span> Вернет то, что вам ценно.')
        counter += 1
        currentMessageLength = singleMessageLength * counter
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.s-footer {
  z-index: 2;
  position: relative;
  padding: 40px 0;
  .container {
    padding-top: 40px;
    max-width: none;
    align-items: stretch;
  }
}
  .tagline-message {
    width: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    color: $pink;
    font-weight: 900;
    letter-spacing: 0.04em;
    line-height: 56px;
    opacity: .4;
    .tagline {
      display: inline-block;
    }
  }
  .logo-link {
    .logo-img {
      width: 92px;
      height: 42px;
    }
  }
  .menu-link {
    margin-bottom: 12px;
  }
  .wrapper-social {
    margin-left: auto;
    display: flex;
    align-items: center;
    .social-link {
      margin: 0 10px;
    }
  }
  .row {
    @include max-w-xs {
      flex-direction: column;
      margin-right: 0;
      margin-left: 0;
    }
    .col {
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      @include max-w-sm {
        width: calc(50% - 20px) !important;
      }
      @include max-w-xs {
        margin-bottom: 20px;
        width: 100% !important;
      }
    }
    .col-logo {
      width: calc(25% - 20px);
      @include max-w-lg {
        width: calc(30% - 20px);
      }
      @include max-w-sm {
        order: 3;
      }
      @include max-w-xs {
        margin: 0;
        order: 4;
      }
      .wrapper-social {
        display: none;
        @include max-w-xs {
          display: flex;
          margin: 10px 0;
        }
      }
    }
    .col-menu {
      width: calc(15% - 20px);
      @include max-w-lg {
        width: calc(20% - 20px);
      }
      @include max-w-sm {
        margin-bottom: 20px;
      }
      @include max-w-xs {
        margin-bottom: 0;
      }
    }
    .col-contacts {
      width: calc(25% - 20px);
      margin-left: auto;
      @include max-w-lg {
        width: calc(30% - 20px);
      }
      @include max-w-sm {
        order: 4;
      }
      @include max-w-xs {
        order: 3;
        margin: 20px 0;
        .wrapper-social {
          display: none;
        }
      }
    }
  }
</style>
